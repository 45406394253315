<template>
    <div>
        <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div style="padding: 20px">
                    <h1 style="font-size: 200%">分时预约管理系统</h1>
                    <p>统一预约来源，优化预约-就诊过程</p>
                    <p>统一数据管理，院前沟通记录，医生信息管理、排班、数据统计分析、分诊叫号</p>
                    <p>
                        <a href="/docs/index.html" target="_blank">API 接口文档</a>
                    </p>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <img src="../../assets/hero.png" class="hidden-sm-and-down" width="500px"/>
            </el-col>
        </el-row>
        <!-- div>
            <h3 style="margin-left:20px;">快捷入口</h3>
            <el-row class="btns">
                <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
                    <router-link to="/main/appoint">
                        <el-button>预约记录</el-button>
                    </router-link>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
                    <router-link to="/main/appoint/form">
                        <el-button>电话预约</el-button>
                    </router-link>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
                    <router-link to="/main/appoint/form">
                        <el-button>VIP 通道</el-button>
                    </router-link>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
                    <router-link to="/main/worksheet">
                        <el-button>门诊排班</el-button>
                    </router-link>
                </el-col>
            </el-row>
            <div class="btns">
            </div>
        </div -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
    }
</script>

<style scoped>
    button {
        margin: 20px;
    }
</style>
